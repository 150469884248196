import { gql } from '@apollo/client';

export const GET_COURSES = gql`
    query GetCourses($input: CoursesListWebInput!) {
        getCourses(input: $input) {
            nodes {
                _id
                name
            }
        }
    }
`;
