import {useQuery} from '@apollo/client';
import {GET_COURSES} from "./graphql/queries/courses";

import './App.css';

function App() {
  const variables = {
    input: {
      page: 1
    }
  }

  const {loading, error, data} = useQuery(GET_COURSES, {variables});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  return (
    <div className="App">
      {data.getCourses.nodes.map(({_id, name}) => (
        <div key={_id}>
          <h3>{name}</h3>
          <br/>
        </div>
      ))}
    </div>
  );
}

export default App;
